import React from "react";
import { Box, Typography, Button } from "@mui/material";
import BackgroundImage from "./back.png"; // Path to your image

const HeroSection = () => {
  return (
    <Box
      sx={{
        height: 500,
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundColor: "#EEF0ED",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        textAlign: "center",
        padding: "0 20px",
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{ py: 2, textShadow: "2px 2px 8px rgba(0,0,0,0.8)" }} // Text shadow for emphasis
      >
        Descubra os nossos produtos
      </Typography>
      <Typography
        variant="h5"
        sx={{ mb: 4, py: 2, textShadow: "1px 1px 6px rgba(0,0,0,0.6)" }} // Consistent text shadow
      >
        Explore as melhores opções possíveis no mercado
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{
          fontWeight: "bold", // Bolder font weight for button text
          padding: "10px 20px", // Larger, more clickable area
          borderRadius: "20px", // Rounded corners for a modern look
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Subtle shadow for 3D effect
          "&:hover": {
            boxShadow: "0px 6px 14px rgba(0, 0, 0, 0.45)", // Enhanced hover effect
          },
        }}
      >
        Olhe nossos produtos
      </Button>
    </Box>
  );
};

export default HeroSection;
