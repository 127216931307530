import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Container,
  Badge,
  useMediaQuery,
  CssBaseline,
  createTheme,
  ThemeProvider,
  IconButton,
  Drawer,
  Typography,
  Box,
  ListItemIcon,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MenuIcon from "@mui/icons-material/Menu";
import { motion } from "framer-motion";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import InfoIcon from "@mui/icons-material/Info";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import SearchBar from "./components/searchbar";
import HeroSection from "./components/HeroSection";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
import ProductList from "./pages/Products";
import AboutUsPage from "./pages/AboutUs";
import ContactUsPage from "./pages/Contact";

const cardVariants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3,
    },
  },
  initial: {
    scale: 1,
  },
};

const theme = createTheme({
  palette: {
    primary: { main: "#333" }, // Dark gray
    secondary: { main: "#ffd600" }, // Vibrant yellow
    background: { default: "#fff" },
    text: { primary: "#333", secondary: "#555" },
  },
  typography: {
    fontFamily: "Helvetica Neue, Arial, sans-serif",
    h5: {
      fontWeight: 600,
    },
    button: {
      textTransform: "none",
    },
  },
});

const promotions = [
  {
    id: 1,
    title: "Oferta Especial 1",
    description: "Aproveite nossos descontos em eletrônicos!",
    image: "https://source.unsplash.com/2EJCSULRwC8/200x140",
  },
  {
    id: 2,
    title: "Desconto Imperdível 2",
    description: "Descontos incríveis em toda a linha de moda!",
    image: "https://source.unsplash.com/W3Jl3jREpDY/200x140",
  },
  {
    id: 3,
    title: "Promoção Relâmpago 3",
    description: "Decore sua casa economizando!",
    image: "https://source.unsplash.com/mGZX2MOPR-s/200x140",
  },
];

const FeaturedProducts = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch("https://fakestoreapi.com/products?limit=10")
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    responsive: [{ breakpoint: 600, settings: { slidesToShow: 1 } }],
  };

  return (
    <Slider {...settings}>
      {products.map((product: any) => (
        <motion.div
          key={product.id}
          variants={cardVariants}
          initial="initial"
          whileHover="hover"
        >
          <Card
            sx={{
              margin: 2,
              boxShadow: "0px 6px 18px rgba(0,0,0,0.1)",
              "&:hover": {
                boxShadow: "0px 6px 20px rgba(0,0,0,0.3)",
              },
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CardActionArea>
              <Badge
                badgeContent="Novo"
                color="secondary"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <CardMedia
                  component="img"
                  image={product.image}
                  alt={product.title}
                  height="140"
                  loading="lazy"
                />
              </Badge>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{ marginBottom: "8px", fontWeight: "bold" }}
                >
                  {product.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {product.description}
                </Typography>
                <Typography variant="body1" color="text.primary">
                  R$ {product.price}
                </Typography>
              </CardContent>
            </CardActionArea>
            <Button variant="contained" color="primary">
              Compre agora
            </Button>
          </Card>
        </motion.div>
      ))}
    </Slider>
  );
};

const Navbar = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <AppBar
      position="static"
      style={{ background: "linear-gradient(120deg, #333 30%, #666 90%)" }}
    >
      <Toolbar>
        <img
          src="logo.png"
          alt="Mini Shop Logo"
          style={{
            height: "10vh",
            marginRight: "10px",
            backgroundColor: "white",
            marginBottom: "1vh",
            marginTop: "1vh",
          }}
        />
        <SearchBar />
        {!isMobile && (
          <>
            <Button color="inherit" component={Link} to="/">
              Home
            </Button>
            <Button color="inherit" component={Link} to="/products">
              Produtos
            </Button>
            <Button color="inherit" component={Link} to="/about">
              Sobre
            </Button>
            <Button color="inherit" component={Link} to="/contact">
              Entre em contato
            </Button>
          </>
        )}
        {isMobile && (
          <IconButton color="inherit" onClick={() => setDrawerOpen(true)}>
            <MenuIcon />
          </IconButton>
        )}
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => setDrawerOpen(false)}
            onKeyDown={() => setDrawerOpen(false)}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/">
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/products">
                  <ListItemIcon>
                    <ShoppingBagIcon />
                  </ListItemIcon>
                  <ListItemText primary="Produtos" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/about">
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sobre" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/contact">
                  <ListItemIcon>
                    <ContactMailIcon />
                  </ListItemIcon>
                  <ListItemText primary="Contato" />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

const Promotions = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  return (
    <Box sx={{ margin: "20px 0" }}>
      <Typography variant="h6" gutterBottom>
        Promoções especiais
      </Typography>
      <Slider {...settings}>
        {promotions.map((promo) => (
          <motion.div
            key={promo.id}
            variants={cardVariants}
            initial="initial"
            whileHover="hover"
          >
            <Card
              sx={{
                margin: "2.5vw",
                transition: "transform 0.5s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0 12px 24px 0 rgba(0,0,0,0.2)",
                },
                borderRadius: "16px", // Arredondamento das bordas
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Sombra mais suave
              }}
            >
              <CardActionArea>
                <Box
                  sx={{
                    position: "relative",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundImage:
                        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)",
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={promo.image}
                    alt={promo.title}
                    height="140"
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 10,
                      left: 0,
                      width: "100%",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    <Typography variant="h5" component="div">
                      {promo.title}
                    </Typography>
                    <Typography variant="body2">{promo.description}</Typography>
                  </Box>
                </Box>
              </CardActionArea>
            </Card>
          </motion.div>
        ))}
      </Slider>
    </Box>
  );
};

const HomePage = () => (
  <Container>
    <HeroSection />
    <Typography variant="h4" gutterBottom component="div" sx={{ marginTop: 2 }}>
      Produtos Destaques
    </Typography>
    <FeaturedProducts />
    <Promotions />
    <Testimonials />
  </Container>
);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
        </Routes>
      </Router>
      <Footer />
    </ThemeProvider>
  );
};

export default App;
