import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";

interface TeamMember {
  id: number;
  name: string;
  title: string;
  imageUrl: string;
}

const TeamGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4),
  justifyContent: "center",
}));

const AboutUsPage: React.FC = () => {
  const teamMembers: TeamMember[] = [
    {
      id: 1,
      name: "John Doe",
      title: "Fundador",
      imageUrl: "https://source.unsplash.com/random",
    },
    {
      id: 2,
      name: "Jane Smith",
      title: "Gerente Geral",
      imageUrl: "https://source.unsplash.com/random",
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Paper
        sx={{
          marginBottom: 2,
          padding: 3,
          textAlign: "center",
          backgroundImage: `url(https://source.unsplash.com/featured/?business)`,
          backgroundSize: "cover",
          color: "white",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Adding an overlay
          }}
        />
        <Typography
          variant="h3"
          component="h1"
          sx={{
            position: "relative",
            zIndex: 1, // Ensure the text is above the overlay
            textShadow: "2px 2px 8px rgba(0,0,0,0.7)", // Adding text shadow for better readability
          }}
        >
          Sobre nós
        </Typography>
        <Typography
          variant="h5"
          sx={{
            position: "relative",
            zIndex: 1,
            textShadow: "2px 2px 8px rgba(0,0,0,0.7)",
          }}
        >
          Inovando uma casa por vez
        </Typography>
      </Paper>
      <Typography variant="h4" sx={{ textAlign: "center", mt: 4 }}>
        Nossa Missão
      </Typography>
      <Typography sx={{ textAlign: "center", mx: 2 }}>
        Entregar os melhores produtos possíveis em um preço acessível
      </Typography>
      <TeamGrid container spacing={4}>
        {teamMembers.map((member) => (
          <Grid item xs={12} sm={6} md={4} key={member.id}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={member.imageUrl}
                alt={member.name}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {member.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {member.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </TeamGrid>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Button variant="contained" color="primary" href="/contact">
          Fale conosco
        </Button>
      </Box>
    </Box>
  );
};

export default AboutUsPage;
