import React from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  styled,
  useTheme,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(6),
  color: theme.palette.common.white,
  backgroundImage: `linear-gradient(to left, rgba(0,0,0,0.7), rgba(0,0,0,0.5)), url(https://source.unsplash.com/featured/?business,tech)`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "100%",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row", // Layout original para telas maiores
    height: "300px",
  },
}));

const ContactUsPage: React.FC = () => {
  const theme = useTheme();
  return (
    <Box sx={{ flexGrow: 1, padding: theme.spacing(3) }}>
      <StyledPaper>
        <Typography variant="h3" component="h1" gutterBottom>
          Fale Conosco
        </Typography>
        <Typography
          variant="h6"
          sx={{
            maxWidth: "600px",
            textAlign: "center",
            margin: theme.spacing(2, 0),
          }}
        >
          Todo esse site é um modelo criado para o nosso portfólio para mostrar
          nossos recursos de design e desenvolvimento de plataformas de comércio
          eletrônico.
        </Typography>
        <Box>
          <img
            src="logo.png"
            alt="Dynamic Visual"
            style={{
              borderRadius: "50%",
              maxWidth: "100%",
              maxHeight: "200px",
              width: "auto",
              height: "auto",
            }}
          />
        </Box>
      </StyledPaper>
      <Box sx={{ textAlign: "center", mt: 4, mx: 2 }}>
        <Typography sx={{ mt: 2 }}>
          Esta página é um mostruário. Para consultas reais ou interação
          adicional, clique abaixo.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href="https://kaiya.dev"
          endIcon={<ArrowForwardIcon />}
          sx={{ mt: 4 }}
        >
          Visite nosso site oficial
        </Button>
      </Box>
    </Box>
  );
};

export default ContactUsPage;
