import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Typography } from "@mui/material";
import ProductItem from "../components/ProductItem";
import ProductDetailModal from "../components/ProductDetailModal";

interface Product {
  id: number;
  title: string;
  price: number;
  image: string;
  description: string;
}

const ProductList: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    fetch("https://fakestoreapi.com/products")
      .then((res) => res.json())
      .then((data) => {
        setProducts(data as Product[]);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Failed to fetch products", err);
        setLoading(false);
      });
  }, []);

  const handleProductClick = (product: Product) => {
    setSelectedProduct(product);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <Typography variant="h4" component="h2" sx={{ margin: 2 }}>
        Nossos Produtos
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={4}>
          {products.map((product) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={product.id}
              onClick={() => handleProductClick(product)}
            >
              <ProductItem product={product} />
            </Grid>
          ))}
          <ProductDetailModal
            product={selectedProduct}
            open={modalOpen}
            onClose={handleCloseModal}
          />
        </Grid>
      )}
    </div>
  );
};

export default ProductList;
