import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

interface Product {
  id: number;
  title: string;
  price: number;
  description: string;
  image: string;
}

interface ProductDetailModalProps {
  product: Product | null;
  open: boolean;
  onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProductDetailModal: React.FC<ProductDetailModalProps> = ({
  product,
  open,
  onClose,
}) => {
  if (!product) return null; // Render nothing if no product is provided

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="product-detail-dialog-title"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="product-detail-dialog-title">
        {product.title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <img
          src={product.image}
          alt={product.title}
          style={{ width: "100%", height: "auto", marginBottom: 16 }}
        />
        <Typography variant="h5">R${product.price}</Typography>
        <Typography variant="body1" style={{ color: "#757575" }}>
          {product.description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          style={{ backgroundColor: "#1976d2", color: "#FFFFFF" }}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductDetailModal;
