import React from "react";
import {
  Box,
  Typography,
  Paper,
  Avatar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
  {
    id: 1,
    name: "Jane Doe",
    title: "Diretor Criativo",
    image: "https://source.unsplash.com/random/100x100?person1",
    quote:
      "Essa loja sempre tem exatamente o que eu preciso, a qualidade é excelente e o atendimento é impecável.",
  },
  {
    id: 2,
    name: "John Smith",
    title: "Cliente Frequente",
    image: "https://source.unsplash.com/random/100x100?person2",
    quote: "Sou cliente há anos e nunca me decepcionei. Altamente recomendado!",
  },
  {
    id: 3,
    name: "Alice Johnson",
    title: "Comprador Profissional",
    image: "https://source.unsplash.com/random/100x100?person3",
    quote:
      "Uma variedade fantástica de produtos e um site fácil de usar. Sempre um serviço excelente!",
  },
];

const Testimonials = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  // Neomorphic style with soft shadows
  const neomorphicStyle = {
    background: theme.palette.background.paper,
    boxShadow: `5px 5px 15px ${theme.palette.grey[500]}, -5px -5px 15px ${theme.palette.grey[50]}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    margin: isMobile ? theme.spacing(1) : theme.spacing(3),
    maxWidth: isMobile ? "70vw" : "50vw", // Adjust max width based on screen size
    "&:hover": {
      boxShadow: `10px 10px 30px ${theme.palette.grey[500]}, -10px -10px 30px ${theme.palette.grey[50]}`,
      transform: "scale(1.05)", // Micro-interaction on hover
      transition: "transform 0.3s ease-in-out",
    },
  };

  return (
    <Box sx={{ padding: 4, position: "relative", overflow: "hidden" }}>
      <Typography variant="h4" gutterBottom>
        O que nossos clientes dizem
      </Typography>
      <Slider {...settings} adaptiveHeight>
        {testimonials.map((testimonial) => (
          <Paper key={testimonial.id} sx={neomorphicStyle}>
            <Box
              sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}
            >
              <Avatar
                src={testimonial.image}
                alt={testimonial.name}
                sx={{ marginRight: 2 }}
              />
              <div>
                <Typography variant="h6" component="div">
                  {testimonial.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.title}
                </Typography>
              </div>
            </Box>
            <Typography variant="body1" style={{ fontStyle: "italic" }}>
              "{testimonial.quote}"
            </Typography>
          </Paper>
        ))}
      </Slider>
    </Box>
  );
};

export default Testimonials;
