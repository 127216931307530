import React from 'react';
import { Box, Grid, Typography, Button, IconButton, Link } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  return (
    <Box sx={{
      bgcolor: 'background.paper',
      color: 'text.secondary',
      py: 3,
      px: 2,
      mt: 5,
      borderTop: `1px solid`,
      borderColor: 'divider'
    }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>Links rápidos</Typography>
          <Link href="/products" color="inherit">Produtos</Link><br/>
          <Link href="/about" color="inherit">Sobre nós</Link><br/>
          <Link href="/contact" color="inherit">Contato</Link>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>Nossas redes sociais</Typography>
          <IconButton aria-label="Facebook">
            <FacebookIcon />
          </IconButton>
          <IconButton aria-label="Twitter">
            <TwitterIcon />
          </IconButton>
          <IconButton aria-label="Instagram">
            <InstagramIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>Newsletter</Typography>
          <Button startIcon={<MailOutlineIcon />} variant="contained" color="primary">
            Se inscreva
          </Button>
        </Grid>
      </Grid>
      <Typography variant="body2" sx={{ mt: 2, display: 'block', textAlign: 'center' }}>
        © 2024 Mini Shop Empresa Kaiya | Todos os direitos reservados.
      </Typography>
    </Box>
  );
};

export default Footer;
